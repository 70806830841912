import React, { useState, useEffect, useRef } from "react";
import { Card, Button } from "../shared";
import ideas from "../../data/ideas";

// Helper Function to Select a Random Idea
const getRandomIdea = (ideas) =>
    ideas[Math.floor(Math.random() * ideas.length)] || null;

// Component: Idle Stage
const IdleStage = ({ risqueScore, onStart }) => (
    <Card title={`Your Risqué Score: ${risqueScore}`}>
        <p className="text-muted text-lg">
            Ready to generate your next role-play idea? Click below to begin!
        </p>
        <Button onClick={onStart} className="animate-fade-in">
            Start Generating
        </Button>
    </Card>
);

// Component: Flashing Stage
const FlashingStage = ({ flashingIdea }) => (
    <Card title="Generating Your Idea...">
        <div className="text-lg font-primary text-accent h-20 flex items-center justify-center animate-glow-pulse">
            {flashingIdea}
        </div>
    </Card>
);

// Component: Result Stage
const ResultStage = ({ selectedIdea }) => (
    <>
        <Card title="Your Next Role-Play Idea">
            <div className="text-xl font-bold text-highlight text-center animate-scale-up">
                {selectedIdea.title}
            </div>
        </Card>
        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 w-full animate-fade-in">
            {selectedIdea.participants.map((participant, index) => (
                <Card key={index} title={participant.title}>
                    <div className="text-muted mb-2">
                        <strong>Role:</strong> {participant.role}
                    </div>
                    <div className="text-muted">
                        <strong>Products:</strong>
                        <ul className="list-disc list-inside">
                            {participant.products.map((product, productIndex) => (
                                <li key={productIndex}>
                                    <a
                                        href={product.url}
                                        target="_blank"
                                        rel="noopener"
                                        className="text-accent hover:underline transition ease-smooth"
                                    >
                                        {product["alt-text"]}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Card>
            ))}
        </div>
    </>
);

// Main Component: Spinner
const Spinner = ({ risqueScore }) => {
    const [stage, setStage] = useState("idle");
    const [flashingIdea, setFlashingIdea] = useState("");
    const [selectedIdea, setSelectedIdea] = useState(null);

    const flashingIntervalRef = useRef(null);
    const resultTimeoutRef = useRef(null);

    const filteredIdeas = ideas.filter((idea) => idea.minScore <= risqueScore);

    useEffect(() => {
        console.log("Filtered Ideas:", filteredIdeas);
    }, [filteredIdeas]);

    const startFlashing = () => {
        flashingIntervalRef.current = setInterval(() => {
            const randomIdea = getRandomIdea(filteredIdeas);
            setFlashingIdea(randomIdea?.title || "No ideas available...");
        }, 200);
    };

    const setFinalResult = () => {
        resultTimeoutRef.current = setTimeout(() => {
            clearInterval(flashingIntervalRef.current);
            const finalIdea = getRandomIdea(filteredIdeas) || {
                title: "No Matching Ideas",
                participants: [],
            };
            setSelectedIdea(finalIdea);
            setStage("result");
        }, Math.random() * 3000 + 5000);
    };

    const startSpinner = () => {
        setStage("flashing");
        startFlashing();
        setFinalResult();
    };

    useEffect(() => {
        return () => {
            clearInterval(flashingIntervalRef.current);
            clearTimeout(resultTimeoutRef.current);
        };
    }, []);

    return (
        <div className="flex flex-col items-center space-y-6 w-full max-w-2xl mx-auto">
            {stage === "idle" && (
                <IdleStage risqueScore={risqueScore} onStart={startSpinner} />
            )}
            {stage === "flashing" && <FlashingStage flashingIdea={flashingIdea} />}
            {stage === "result" && selectedIdea && (
                <ResultStage selectedIdea={selectedIdea} />
            )}
        </div>
    );
};

export default Spinner;
