const ideas = [
    {
      title: "The Seductive Stranger",
      description: "One of you plays a mysterious stranger in a bar who the other must seduce.",
      tags: ["seduction", "mystery", "role-switching"],
      minScore: 7, // Minimum score required to access this idea
      participants: [
        {
          title: "Stranger",
          role: "Be mysterious and alluring. Respond only when approached and keep details about yourself vague.",
          products: [
            {
              id: 0,
              title: "Mysterious Fedora",
              url: "http://example-affiliate-link.com/fedora",
              thumbnail: "http://example-affiliate-link.com/fedora-thumbnail.jpg",
              "alt-text": "A stylish fedora hat perfect for a mysterious look",
            },
          ],
        },
        {
          title: "Approacher",
          role: "Confidently engage the stranger, trying to uncover details while maintaining intrigue.",
          products: [
            {
              id: 1,
              title: "Intriguing Journal",
              url: "http://example-affiliate-link.com/journal",
              thumbnail: "http://example-affiliate-link.com/journal-thumbnail.jpg",
              "alt-text": "A leather-bound journal for keeping secrets",
            },
          ],
        },
      ],
    },
    {
      title: "The Boss and Employee",
      description: "A workplace scenario where one is the boss and the other is a mischievous employee.",
      tags: ["authority", "power-dynamics", "office"],
      minScore: 6, // Minimum score required to access this idea
      participants: [
        {
          title: "Boss",
          role: "Maintain authority but allow the employee to challenge boundaries slightly.",
          products: [
            {
              id: 2,
              title: "Executive Pen Set",
              url: "http://example-affiliate-link.com/pen",
              thumbnail: "http://example-affiliate-link.com/pen-thumbnail.jpg",
              "alt-text": "A premium pen set to assert authority",
            },
          ],
        },
        {
          title: "Employee",
          role: "Be playful and push boundaries in a lighthearted but professional manner.",
          products: [
            {
              id: 3,
              title: "Colorful Sticky Notes",
              url: "http://example-affiliate-link.com/sticky-notes",
              thumbnail: "http://example-affiliate-link.com/sticky-notes-thumbnail.jpg",
              "alt-text": "Bright sticky notes for mischievous messages",
            },
          ],
        },
      ],
    },
    {
      title: "Fantasy Adventure",
      description: "Take on roles of a knight and a captured royalty who must escape together.",
      tags: ["adventure", "fantasy", "rescue"],
      minScore: 8, // Minimum score required to access this idea
      participants: [
        {
          title: "Knight",
          role: "Be brave and decisive, focusing on rescuing and guiding the royalty to safety.",
          products: [
            {
              id: 4,
              title: "Heroic Sword",
              url: "http://example-affiliate-link.com/sword",
              thumbnail: "http://example-affiliate-link.com/sword-thumbnail.jpg",
              "alt-text": "A replica sword for the brave knight",
            },
          ],
        },
        {
          title: "Royalty",
          role: "Act grateful but also provide clever solutions to aid in the escape.",
          products: [
            {
              id: 5,
              title: "Regal Crown",
              url: "http://example-affiliate-link.com/crown",
              thumbnail: "http://example-affiliate-link.com/crown-thumbnail.jpg",
              "alt-text": "A golden crown to signify royalty",
            },
          ],
        },
      ],
    },
    {
      title: "Fitness Trainer and Client",
      description: "A gym scenario where one is a trainer helping the other with intense workouts.",
      tags: ["fitness", "personal", "gym"],
      minScore: 5, // Minimum score required to access this idea
      participants: [
        {
          title: "Trainer",
          role: "Provide intense workout routines and encourage the client to push their limits.",
          products: [
            {
              id: 6,
              title: "Resistance Bands Set",
              url: "http://example-affiliate-link.com/resistance-bands",
              thumbnail: "http://example-affiliate-link.com/resistance-bands-thumbnail.jpg",
              "alt-text": "A set of resistance bands for effective training",
            },
          ],
        },
        {
          title: "Client",
          role: "Follow the trainer's instructions while demonstrating effort and dedication.",
          products: [
            {
              id: 7,
              title: "Water Bottle",
              url: "http://example-affiliate-link.com/water-bottle",
              thumbnail: "http://example-affiliate-link.com/water-bottle-thumbnail.jpg",
              "alt-text": "A reusable water bottle to stay hydrated",
            },
          ],
        },
      ],
    },
    {
      title: "Doctor and Patient",
      description: "Explore a playful doctor-patient dynamic during a routine checkup.",
      tags: ["medical", "intimate", "trust"],
      minScore: 4, // Minimum score required to access this idea
      participants: [
        {
          title: "Doctor",
          role: "Conduct a thorough examination while maintaining a calm and professional demeanor.",
          products: [
            {
              id: 8,
              title: "Stethoscope",
              url: "http://example-affiliate-link.com/stethoscope",
              thumbnail: "http://example-affiliate-link.com/stethoscope-thumbnail.jpg",
              "alt-text": "A realistic stethoscope for doctor role-play",
            },
          ],
        },
        {
          title: "Patient",
          role: "Be cooperative but also curious about the process to spark engaging interaction.",
          products: [
            {
              id: 9,
              title: "Medical Gown",
              url: "http://example-affiliate-link.com/medical-gown",
              thumbnail: "http://example-affiliate-link.com/medical-gown-thumbnail.jpg",
              "alt-text": "A comfortable medical gown for patients",
            },
          ],
        },
      ],
    },
  ];
  
  export default ideas;
  