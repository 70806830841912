import React from "react";

const Button = ({ onClick, disabled, children }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`rounded-lg bg-accent text-highlight font-bold px-6 py-3 shadow-button transition ${
                disabled
                    ? "cursor-not-allowed bg-gray-400 text-gray-700"
                    : "hover:bg-highlight hover:text-accent"
            }`}
        >
            {children}
        </button>
    );
};

export default Button;
