import React, { useState } from "react";
import { Navigation, Spinner } from "./components";
import Interview from "./components/interview/Interview";

function App() {
    const [risqueScore, setRisqueScore] = useState(null);

    return (
        <div className="bg-dark-gradient min-h-screen">
            <Navigation />
            <main className="flex flex-col items-center justify-center py-20">
                {risqueScore === null ? (
                    <Interview onComplete={setRisqueScore} />
                ) : (
                    <Spinner risqueScore={risqueScore} />
                )}
            </main>
        </div>
    );
}

export default App;
