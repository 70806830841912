import React from "react";

const QuestionCard = ({ question, onAnswer }) => {
    return (
        <div className="bg-card p-8 rounded-lg shadow-card space-y-6 transition-transform duration-500">
            <h2 className="text-2xl font-primary text-highlight text-center">{question.text}</h2>
            <div className="space-y-4">
                {question.options.map((option, index) => (
                    <button
                        key={index}
                        onClick={() => onAnswer(option)}
                        className="block w-full px-4 py-3 bg-accent text-highlight font-bold rounded-lg shadow-lg hover:bg-highlight hover:text-accent transition transform hover:scale-105"
                    >
                        {option.text}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuestionCard;
