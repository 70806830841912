import React from "react";

const Navigation = () => {
    return (
<nav className="bg-card p-4 shadow-md">
    <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-primary text-highlight">Sexual Role Play</h1>
        <ul className="flex space-x-4">
            <li>
                <a href="#about" className="hover:text-muted">
                    About
                </a>
            </li>
            <li>
                <a href="#contact" className="hover:text-muted">
                    Contact
                </a>
            </li>
        </ul>
    </div>
</nav>

    );
};

export default Navigation;
