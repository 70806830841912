import React from "react";

const Card = ({ title, children }) => {
    return (
        <div className="bg-card text-highlight p-6 rounded-lg shadow-card space-y-4">
            {title && <h2 className="text-xl font-primary text-accent">{title}</h2>}
            <div className="text-muted">{children}</div>
        </div>
    );
};

export default Card;
